<template>
  <v-card>
    <v-row>
      <v-col lg="12">
      <v-card-title>
        <v-btn x-small fab :disabled="currentPage === 1" @click="fetchNews(currentPage - 1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <h5>{{ $t('message.news.news') }}</h5>
        <v-spacer></v-spacer>
        <v-btn x-small fab :disabled="!hasNextPage" @click="fetchNews(currentPage + 1)">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-title>
        <v-row no-gutters v-if="fieldsLoading">
          <v-col v-for="n in 9" :key="n" cols="12" sm="6" lg="3">
            <v-sheet class="mt-2">
              <v-skeleton-loader class="mx-auto" type="list-item" />
            </v-sheet>
          </v-col>
        </v-row>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(item, index) in news"
            :key="index"
            @click="markAsRead(item.id)"
            class="mb-2 mt-0"
            :style="{backgroundColor: 'rgb(232, 234, 246)'}"
          >
          <v-expansion-panel-header>
            <v-container class="pa-0" >
              <v-row no-gutters>
                <v-col cols="12" md="7"  >
                  <h3 class="pb-3">{{ item.title }}</h3>
                </v-col>
                <v-col cols="12" md="5" class="text-md-right">
                  <span>{{ item.published_date }}</span> - <span class="mr-md-5">{{ item.fullname }}</span>
                </v-col>
                <v-col cols="12 mt-md-0  mt-3">
                  <div v-html="item.short_description"></div>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="item.description"></div>
          </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { hostAppApi } from '../../plugins/axios_settings'

export default {
  data: () => ({
    news: [],
    openedPanels: [],
    fieldsLoading: true,
    currentPage: 1,
    hasNextPage: false
  }),
  mounted () {
    this.fetchNews(this.currentPage)
  },
  computed: {
    ...mapGetters(['getHostRefApi']),
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  methods: {
    fetchNews (page) {
      this.news = []
      this.fieldsLoading = true
      hostAppApi.get(`${this.getHostRefApi}news?page=${page}`)
        .then(({ data }) => {
          this.fieldsLoading = false
          this.news = data?.data?.map(item => ({
            title: item.title,
            short_description: item.short_description?.replace(/<p>/g, '<p style="margin: 0">') || '',
            id: item.id,
            published_date: item.published_date,
            fullname: item.createduser.fullname,
            description: (this.isDesktop ? item.description?.replace(/height="\d+"/, 'height="525"') : item.description)
              ?.replace(/width="\d+"/, 'width="100%"')
              .replace(/<ul>/, '<ul style="margin: 0; padding: 0; list-style: none">') || ''
          }))
          this.currentPage = data.current_page
          this.hasNextPage = !!data.next_page_url
        })
        .catch((error) => {
          this.fieldsLoading = false
          console.log(error)
        })
    },
    markAsRead (id) {
      if (!this.openedPanels.includes(id)) {
        this.openedPanels.push(id)
        hostAppApi.put(`${this.getHostRefApi}news/${id}/read`).catch((error) => { console.log(error) })
      }
    }
  }
}
</script>
